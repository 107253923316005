import React from 'react';
import '../assets/styles/Layout.scss'
import Footer from '../components/Footer/Footer';
import HomeContact from '../components/blocks/HomeContact/HomeContact';
import Header from '../components/Header/Header';


const Layout = ({ children }) => {
  return (
    <>
      <Header />
      <main className="main_page">
        {children}
      </main>
      <HomeContact />
      <Footer />
    </>
  );
};

export default Layout;
