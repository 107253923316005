import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import SEO from '../../components/Seo';
import { isBrowser } from '../../../Utils/Utils';
import play_btn from "../../assets/images/play_btn.png"
import arrow_down_deg from "../../assets/images/arrow_down_deg.png"
import ReactPlayer from 'react-player'
import './Work.scss'
import Layout from '../../layout/layout';
import { useLocation } from "@reach/router";

const WorkItem = ({ data }) => {
  const location = useLocation();
  const [workPosition, setWorkPosition] = useState()
  const [workURL, setWorURL] = useState([])
  const [play, setPlay] = useState(false)
  const workItemData = data.contentfulWorkItem;
  const workList = data?.contentfulHomeBlockWork.workItems

  useEffect(() => {
    workList.map(url => workURL.push(url.pageUrl))
    setWorkPosition(workURL.indexOf(workItemData.pageUrl))

    if (!isBrowser) {
      return;
    }
    return window.scrollTo(0, 0);

  }, [])

  var videoPath = "https://everydaycontent.fr/videos/";

  return (
    <Layout>
      <SEO
        lang="fr"
        title={'Everyday Content - Work | ' + workItemData?.clientName || ''}
        description={'Everyday Content - Work | ' + workItemData?.clientName || ''}
      />
      <div className="page-work inner-wrap">
        <div className='page-content inner-page'>

          <div className='project-wrap'>
            <a href="/" className="btn-back">
              <span>RETOUR</span>
            </a>
            <ul>
              <li className="mySlides fade" data-project-id="yves-rocher">
                <div className="logo-casto">
                  <GatsbyImage image={getImage(workItemData.logo)} alt={workItemData.logo.title || workItemData.pageUrl} />
                </div>
                <h3 className="tt-3">{workItemData.pageTitle}</h3>

                <div className="arrow-deg-petit">
                  <img src={arrow_down_deg} alt="" />
                </div>
                <div onClick={() => { if (play) { setPlay(false) } }} className="block-video">
                  {
                    !play &&
                    <div onClick={() => setPlay(true)} className="video-cover">
                      <div className="btn-play">
                        <img src={play_btn} alt="" />
                      </div>
                    </div>
                  }
                  <ReactPlayer url={videoPath + workItemData.urlVideo}
                    playing={play} loop
                    controls={false}
                    muted={true}
                    width="100%"
                    height="100%"
                  />
                </div>
                <div className="innerpage-wrap">
                  <div className="block-txt txt-intro" dangerouslySetInnerHTML={{ __html: workItemData.description?.childMarkdownRemark.html }}>
                  </div>
                </div>
              </li>
            </ul>
          </div>

          <div className="bandeau-nav">


            <a href={`/works/${workURL[workPosition - 1]}`} className={`prev ${isBrowser && (location.pathname.includes(`/works/${workList[0]?.pageUrl}`)) ? "disabled" : ""}`}>
              <span className="arrow"></span>
              PROJET PRÉCÉDENT
            </a>


            <a href={`/works/${workURL[workPosition + 1]}`} className={`next ${workURL[workURL.length - 1] === workItemData.pageUrl ? "disabled" : ""}`}>
              PROJET SUIVANT
              <span className="arrow"></span>
            </a>
          </div>
        </div>
      </div>
    </Layout >
  )
};

// WorkItem.propTypes = {
//   data : PropTypes.object.isRequired,
// };

export const query = graphql`
  query($pageUrl : String!) {
    contentfulWorkItem(pageUrl : { eq: $pageUrl}) {
      clientName
      pageUrl
      pageTitle
      logo {
        gatsbyImageData
      }
      urlVideo
      description {
        childMarkdownRemark {
          html
        }
      }
    }
    contentfulHomeBlockWork {
      workItems {
        pageUrl
      }
    }
  }
  `

export default WorkItem;